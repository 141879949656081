import { useMemo } from "react";
import { useStylesheet } from "../contexts";
function useA11yId(stylsheet) {
  const {
    functions: {
      className
    }
  } = stylsheet || useStylesheet();
  return useMemo(() => className(), []);
}
export { useA11yId };