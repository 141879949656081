import { extendCommonGroupsStylesheet } from "@devowl-wp/web-cookie-banner";
import { useStylesheet } from "../../../contexts";
const GroupList = _ref => {
  let {
    children
  } = _ref;
  const {
    GroupList
  } = useStylesheet().extend(...extendCommonGroupsStylesheet);
  return h(GroupList, null, children);
};
export { GroupList };