import { throttling } from ".";

/**
 * Compute a CSS variable from other CSS variables. Use this only, if native CSS
 * functions like `calc` does not suffice.
 */

const UPDATE_EVENT_PREFIX = `css-var-update-`;
const dispatchVariableUpdateEvent = (variableName, _ref) => {
  let {
    mainElement
  } = _ref;
  //const previous = oVarsVal[variableName];
  mainElement.dispatchEvent(new CustomEvent(`${UPDATE_EVENT_PREFIX}${variableName}`, {
    detail: {}
  }));
};
const createComputedFactory = (_ref2, _ref3) => {
  let {
    mainElement,
    varsVal
  } = _ref2;
  let {
    variable,
    vars: varsFn
  } = _ref3;
  return (vars, calculate, suffix, doThrottling) => {
    let updater;
    const variableNames = vars.map(c => typeof c === "function" ? c(false) : undefined);

    // Throttle the "expensive" calculation to avoid multiple executions when
    // e.g. running update of `createVarFactory#vars()`.
    const doCalc = () => calculate(variableNames.map(v => varsVal.get(v)));
    const throttleCalc = throttling(doThrottling || 0, () => updater(doCalc()));

    // Attach to update events
    for (const variableName of variableNames) {
      mainElement.addEventListener(`${UPDATE_EVENT_PREFIX}${variableName}`, throttleCalc);
    }

    // Write the variable once
    const calculated = doCalc();
    const createdVar = typeof calculated === "object" && !Array.isArray(calculated) ? (() => {
      const r = varsFn(calculated, undefined);
      updater = r[1];
      return r[0];
    })() : (() => {
      const r = variable(calculated, undefined, suffix);
      updater = r.update;
      return r;
    })();

    // Modify updater functionality to not take any parameter and do a recalculate
    createdVar.update = () => throttleCalc();
    return createdVar;
  };
};
export { dispatchVariableUpdateEvent, createComputedFactory };