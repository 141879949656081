const extendCommonGroupsStylesheet = [Symbol("extendCommonGroupsStylesheet"), (_ref, _ref2) => {
  let {
    jsx,
    boolIf,
    boolNot
  } = _ref;
  let {
    group,
    design,
    decision,
    bodyDesign,
    boolLargeOrMobile
  } = _ref2;
  const [DottedGroupList] = jsx("fieldset", {
    classNames: "dotted-groups",
    all: "unset",
    marginTop: "10px",
    lineBreak: "anywhere",
    lineHeight: 2,
    pseudos: {
      ">span,>label": {
        paddingRight: "10px",
        fontSize: boolIf({
          when: bodyDesign.dottedGroupsInheritFontSize(),
          then: boolLargeOrMobile(design.fontSize, boolIf),
          or: boolLargeOrMobile(bodyDesign.dottedGroupsFontSize, boolIf)
        }),
        whiteSpace: "nowrap",
        display: "inline-block"
      },
      ">span>i": {
        color: bodyDesign.dottedGroupsBulletColor()
      },
      ">span>i::after": {
        paddingRight: "5px",
        display: "inline-block",
        content: "'●'"
      },
      ">span>span": {
        verticalAlign: "middle",
        cursor: boolIf(decision.groupsFirstView, "pointer")
      }
    }
  });
  const [GroupList] = jsx("div", {
    classNames: "groups",
    marginTop: "10px",
    clear: "both",
    pseudos: {
      ">div:not(:last-of-type)": {
        marginBottom: boolLargeOrMobile(group.groupSpacing, boolIf)
      }
    }
  });
  const [Group] = jsx("div", {
    classNames: "group",
    background: boolIf(boolNot(group.groupInheritBg), group.groupBg()),
    padding: boolLargeOrMobile(group.groupPadding, boolIf),
    borderRadius: group.groupBorderRadius(),
    textAlign: "left",
    borderColor: group.groupBorderColor(),
    borderStyle: "solid",
    borderWidth: boolLargeOrMobile(group.groupBorderWidth, boolIf)
  });
  const [GroupInner] = jsx("fieldset", {
    classNames: "group-inner",
    all: "unset",
    textAlign: "left",
    color: group.headlineFontColor(),
    fontSize: boolLargeOrMobile(group.headlineFontSize, boolIf),
    fontWeight: group.headlineFontWeight()
  });
  const [GroupDescription] = jsx("div", {
    classNames: "group-description",
    color: group.descriptionFontColor(),
    fontSize: boolLargeOrMobile(group.descriptionFontSize, boolIf),
    marginTop: "5px"
  });
  const [Cookie] = jsx("div", {
    classNames: "cookie",
    marginTop: "10px",
    pseudos: {
      // Checkbox or title
      ">label:first-of-type": {
        display: "block",
        marginBottom: "10px"
      }
    }
  });
  const [CookieProperty] = jsx("div", {
    classNames: "cookie-prop",
    borderStyle: "solid",
    borderColor: group.groupBorderColor(),
    borderWidth: "0px",
    borderLeftWidth: boolLargeOrMobile(group.groupBorderWidth, boolIf),
    paddingLeft: "15px",
    pseudos: {
      ">a": {
        wordBreak: "break-all"
      }
    }
  });
  return {
    DottedGroupList,
    GroupList,
    Group,
    GroupInner,
    GroupDescription,
    Cookie,
    CookieProperty
  };
}];
export { extendCommonGroupsStylesheet };