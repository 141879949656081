import { useBanner, useBannerAnimation, useBannerStylesheet, useResettableAnimation, yieldLazyLoad } from "../../..";
import { AnimatedCss } from "../..";
const BannerContent = yieldLazyLoad(import( /* webpackMode: "eager" */"./content").then(_ref => {
  let {
    BannerContent
  } = _ref;
  return BannerContent;
}));
const BannerAnimatedContent = () => {
  const {
    inner
  } = useBannerStylesheet();
  const banner = useBanner();
  const {
    layout: {
      animationInDuration,
      animationOutDuration
    },
    animationVisible
  } = banner;
  const {
    useAnimationIn,
    useAnimationOut
  } = useBannerAnimation()(banner.layout);
  const [stateAnimationIn, stateAnimationInDuration] = useResettableAnimation(useAnimationIn, animationInDuration);
  const [stateAnimationOut, stateAnimationOutDuration] = useResettableAnimation(useAnimationOut === "none" ? "fadeOut" : useAnimationOut, useAnimationOut === "none" ? 0 : animationOutDuration);
  return h(AnimatedCss, {
    animationIn: stateAnimationIn,
    animationInDuration: stateAnimationInDuration,
    animationOut: stateAnimationOut,
    animationOutDuration: stateAnimationOutDuration,
    isVisible: animationVisible,
    className: inner
  }, h(BannerContent, null));
};
export { BannerAnimatedContent };