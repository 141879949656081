import { useBannerOrBlocker } from ".";
function useLegalLinks() {
  const {
    links = [],
    websiteOperator
  } = useBannerOrBlocker();
  const validLinks = links.filter(_ref => {
    let {
      label,
      url
    } = _ref;
    return label && url;
  });
  const privacyPolicy = validLinks.find(_ref2 => {
    let {
      pageType
    } = _ref2;
    return pageType === "privacyPolicy";
  });
  const legalNotice = validLinks.find(_ref3 => {
    let {
      pageType
    } = _ref3;
    return pageType === "legalNotice";
  });
  return {
    privacyPolicy: privacyPolicy ? {
      url: privacyPolicy.url,
      label: privacyPolicy.label
    } : false,
    legalNotice: legalNotice ? {
      url: legalNotice.url,
      label: legalNotice.label
    } : false,
    contactForm: (websiteOperator === null || websiteOperator === void 0 ? void 0 : websiteOperator.contactFormUrl) || undefined
  };
}
export { useLegalLinks };