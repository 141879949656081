import { useBanner, useBannerStylesheet } from "../../..";
import { Fragment, useMemo, useState, useEffect } from "react";
import { extendCommonStylesheet } from "@devowl-wp/web-cookie-banner";
const SELECT_NONE = -1;
const BannerHistorySelect = () => {
  const {
    Select
  } = useBannerStylesheet().extend(...extendCommonStylesheet);
  const banner = useBanner();
  const [selectedId, setSelectedId] = useState(SELECT_NONE);
  const {
    activeAction,
    history,
    selectHistoryEntry,
    i18n: {
      historyLabel,
      historySelectNone
    }
  } = banner;
  const selectedHistoryItem = useMemo(() => {
    var _history$filter;
    return history === null || history === void 0 ? void 0 : (_history$filter = history.filter(_ref => {
      let {
        id
      } = _ref;
      return id === selectedId;
    })) === null || _history$filter === void 0 ? void 0 : _history$filter[0];
  }, [selectedId]);

  // Reset select after exit history dialog
  useEffect(() => {
    if (!activeAction) {
      setSelectedId(SELECT_NONE);
    } else if (activeAction === "history" && history !== null && history !== void 0 && history.length) {
      setSelectedId(history[0].id);
    }
  }, [activeAction, history]);

  // History can only contain my data, so we can pick the first entry for the history
  const uuid = selectedHistoryItem === null || selectedHistoryItem === void 0 ? void 0 : selectedHistoryItem.uuid;
  return h(Fragment, null, historyLabel, "\xA0", h(Select, {
    disabled: !(history !== null && history !== void 0 && history.length),
    value: selectedId,
    onChange: e => {
      const value = +e.target.value;
      history.forEach(_ref2 => {
        let {
          id,
          decision,
          groups,
          tcf
        } = _ref2;
        if (id === value) {
          setSelectedId(id);
          selectHistoryEntry(groups, decision, tcf);
        }
      });
    }
  }, (history === null || history === void 0 ? void 0 : history.length) > 0 ? history.map(_ref3 => {
    let {
      id,
      isDoNotTrack,
      isUnblock,
      isForwarded,
      created
    } = _ref3;
    return h("option", {
      key: id,
      value: id
    }, new Date(created).toLocaleString(document.documentElement.lang), isDoNotTrack ? " (Do Not Track)" : "", isUnblock ? " (Content Blocker)" : "", isForwarded ? " (Consent Forwarding)" : "");
  }) : h("option", {
    value: SELECT_NONE
  }, historySelectNone)), h("div", {
    style: {
      opacity: 0.5,
      marginTop: 5
    }
  }, "UUID: ", uuid || "-"));
};
export { BannerHistorySelect };