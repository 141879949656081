import { getUserDecision } from "../decision";

/**
 * Has the user already consent to the latest requested revision (hash)?
 */
const preDecisionGatewayUserConsent = async _ref => {
  let {
    decisionCookieName,
    revisionHash
  } = _ref;
  const decision = getUserDecision(decisionCookieName);
  if (decision === false) {
    return false;
  }
  const {
    revision
  } = decision;
  return revisionHash === revision ? "consent" : false;
};
export { preDecisionGatewayUserConsent };