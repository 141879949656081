/**
 * Setting this attribute allows to short circuit instead of going upwards all parents. This is useful
 * if you use React's `createPortal`.
 */
const HTML_ATTRIBUTE_UNIQUE_IDENTIFIER = "interaction-player-uqid";

/**
 * If set allows you to skip this element for a next-sibling element interaction.
 * That means a `div:nth-child(3)` click would result in `div:nth-child(2)`.
 */
const HTML_ATTRIBUTE_PLAYER_SKIP = "interaction-player-skip";
const calcFullSelector = function (elm) {
  var _elm;
  let until = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : document.body;
  if (elm === until || !((_elm = elm) !== null && _elm !== void 0 && _elm.parentElement)) {
    return false;
  }
  const names = [];
  while (elm !== until && elm) {
    const uqid = elm.getAttribute(HTML_ATTRIBUTE_UNIQUE_IDENTIFIER);
    if (uqid) {
      names.unshift(`[${HTML_ATTRIBUTE_UNIQUE_IDENTIFIER}="${uqid}"]`);
      break;
    }
    let c = 1;
    let e = elm;
    for (; e.previousElementSibling; e = e.previousElementSibling, c++);

    // Got this item accidentally captured through a click event? For example pointer events and bubbling
    if (elm.tagName === "HTML") {
      return false;
    }
    names.unshift(`${elm.tagName}:nth-child(${c})`);
    elm = elm.parentElement;
    if (elm === until) {
      break;
    }
  }
  return names.join(">").toLowerCase();
};
const resolveFullSelector = (element, selector) => {
  if (selector.indexOf(HTML_ATTRIBUTE_UNIQUE_IDENTIFIER) > -1) {
    return document.querySelector(selector);
  }
  const selectorParts = selector.split(">");
  for (const selectorPart of selectorParts) {
    if (element) {
      const previousElement = element;
      element = element.querySelector(`:scope >${selectorPart}`);

      // Does it have a element which should be skipped previously?
      while ((_element = element) !== null && _element !== void 0 && _element.hasAttribute(HTML_ATTRIBUTE_PLAYER_SKIP)) {
        var _element;
        element = previousElement.querySelector(`:scope >${selectorPart.replace(/nth-child\((\d+)\)$/, (m, idx) => `nth-child(${+idx + 1})`)}`);
      }
    } else {
      return undefined;
    }
  }
  return element;
};
export { calcFullSelector, resolveFullSelector, HTML_ATTRIBUTE_UNIQUE_IDENTIFIER, HTML_ATTRIBUTE_PLAYER_SKIP };