import _extends from "@babel/runtime/helpers/extends";
import { extendCommonButtonsStylesheet } from "@devowl-wp/web-cookie-banner";
import { useCallback, useState } from "react";
import { useStylesheet } from "../../contexts";
import { AsciiSpinner } from "..";
import { useBannerOrBlocker } from "../../hooks";
const Button = _ref => {
  let {
    type,
    children,
    busyOnClick = true,
    forceShow,
    onClick,
    framed,
    sticky,
    order,
    className,
    ...rest
  } = _ref;
  const {
    buttons: {
      [type]: Button
    },
    framed: framedClass,
    sticky: stickyClass,
    forceShowButton: forceShowButtonClassName
  } = useStylesheet().extend(...extendCommonButtonsStylesheet);
  const {
    decision,
    saveButton
  } = useBannerOrBlocker();
  const buttonType = type === "save" ? saveButton.type : decision[type];
  const [isBusy, setIsBusy] = useState(false);
  const handleOnClick = useCallback(e => {
    if (!isBusy) {
      e.preventDefault();
      busyOnClick && setIsBusy(true);
      onClick === null || onClick === void 0 ? void 0 : onClick(e);
    }
  }, [onClick, isBusy, busyOnClick]);
  return h(Button, _extends({
    onClick: handleOnClick,
    className: [framed ? framedClass : undefined, sticky ? stickyClass : undefined, forceShow ? forceShowButtonClassName : undefined, className].filter(Boolean).join(" "),
    style: {
      order
    },
    "data-order": order,
    role: buttonType === "button" ? "button" : undefined
  }, rest), h("span", null, isBusy ? h(AsciiSpinner, null) : children));
};
export { Button };