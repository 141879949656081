import _extends from "@babel/runtime/helpers/extends";
import { useMemo } from "react";
import { renderIconDefinitionToSVGElement } from "@ant-design/icons-svg/es/helpers";

/**
 * Inject a plain SVG icon from `@ant-design/icons-svg`. This reduces the bundle size.
 * You can determine yourself to use `@ant-design/icons` or this helper component. For example,
 * this component is preferred for website bundles where website size matters.
 *
 * @see https://app.clickup.com/t/60pz5t
 */
const PlainAntdIconAsSvg = _ref => {
  let {
    icon,
    ...rest
  } = _ref;
  const svgString = useMemo(() => renderIconDefinitionToSVGElement(icon, {
    extraSVGAttrs: {
      style: "width:auto;height:100%;",
      fill: "currentColor"
    }
  }), [icon]);
  return h("div", _extends({}, rest, {
    dangerouslySetInnerHTML: {
      __html: svgString
    }
  }));
};
export { PlainAntdIconAsSvg };