import { useEffect, useState } from "react";
import { useBanner } from "../../..";
function useBannerHistory() {
  const banner = useBanner();
  const {
    consent,
    groups,
    tcf,
    activeAction,
    selectHistoryEntry,
    fetchHistory
  } = banner;

  // Save the current consent and group settings to restore
  const [originalConsent, setOriginalConsent] = useState({
    consent,
    groups,
    tcf
  });

  // Listen to consent changes and save it as original consent (only when non-history)
  useEffect(() => {
    if (!activeAction) {
      setOriginalConsent({
        consent,
        groups,
        tcf
      });
    }
  }, [consent, groups, tcf, activeAction]);
  useEffect(() => {
    async function fetch() {
      const result = await fetchHistory();

      // Initially select first item
      if (result.length) {
        const [{
          groups,
          decision,
          tcf
        }] = result;
        selectHistoryEntry(groups, decision, tcf);
      } else {
        selectHistoryEntry([], []);
      }
    }
    if (activeAction === "history") {
      // Save state of original consent
      setOriginalConsent({
        consent,
        groups,
        tcf
      });
      fetch();
    } else {
      // If we close the history, restore the original state
      const {
        groups,
        consent: {
          groups: consentGroups
        },
        tcf
      } = originalConsent;
      selectHistoryEntry === null || selectHistoryEntry === void 0 ? void 0 : selectHistoryEntry(groups, consentGroups, tcf);
    }
  }, [activeAction]);
}
export { useBannerHistory };