import { useMemo, useCallback, Fragment } from "react";
import { CONTEXT_LATEST_DESIGN_VERSION } from "../../../types";
import { CookieProperty } from ".";
import { useBannerOrBlocker, useLegalLinks } from "../../../hooks";
import { applyDynamicsToHtml } from "@devowl-wp/headless-content-unblocker";
import { EServiceTemplateDataProcessingInCountriesSpecialTreatment } from "@devowl-wp/api-real-cookie-banner";
import { reactNl2Br } from "../../../utils";
function useDurationText() {
  const {
    i18n: {
      durationUnit: durationUnitTexts
    },
    designVersion
  } = useBannerOrBlocker();
  return useCallback((duration, unit) => {
    return `${duration} ${designVersion < 6 ? durationUnitTexts[unit] : durationUnitTexts[duration > 1 ? "nx" : "n1"][unit]}`;
  }, [durationUnitTexts]);
}
const CookiePropertyList = _ref => {
  let {
    cookie: {
      purpose,
      isProviderCurrentWebsite,
      provider,
      providerContact = {},
      providerPrivacyPolicyUrl,
      providerLegalNoticeUrl,
      legalBasis,
      ePrivacyUSA,
      dataProcessingInCountries,
      dataProcessingInCountriesSpecialTreatments,
      isEmbeddingOnlyExternalResources,
      technicalDefinitions,
      codeDynamics
    },
    isEssentialGroup
  } = _ref;
  const {
    i18n,
    isEPrivacyUSA,
    isDataProcessingInUnsafeCountries,
    iso3166OneAlpha2,
    dataProcessingInUnsafeCountriesSafeCountries,
    designVersion,
    websiteOperator
  } = useBannerOrBlocker();
  const generateDurationText = useDurationText();
  const types = getCookieTypeLocalizationMap();
  const dataProcessingInUnsafeCountries = useMemo(() => {
    const mapCountryIso = c => iso3166OneAlpha2[c] || c;
    return isDataProcessingInUnsafeCountries ? calculateUnsafeCountries({
      dataProcessingInCountries,
      safeCountries: dataProcessingInUnsafeCountriesSafeCountries,
      specialTreatments: dataProcessingInCountriesSpecialTreatments.filter(e => [
      // Ignore the following special treatments for displaying unsecuring countries
      EServiceTemplateDataProcessingInCountriesSpecialTreatment.StandardContractualClauses].indexOf(e) === -1),
      designVersion
    }).map(mapCountryIso) : [];
  }, [isDataProcessingInUnsafeCountries, dataProcessingInUnsafeCountriesSafeCountries, dataProcessingInCountriesSpecialTreatments, dataProcessingInCountries, iso3166OneAlpha2, designVersion]);

  // Calculate provider-specific details
  const {
    legalNotice,
    privacyPolicy,
    contactForm
  } = useLegalLinks();
  const providerData = useMemo(() => {
    if (isProviderCurrentWebsite && websiteOperator) {
      const {
        address,
        country,
        contactEmail,
        contactPhone
      } = websiteOperator;
      return {
        provider: [address, iso3166OneAlpha2[country] || country].filter(Boolean).join(", "),
        contact: {
          email: contactEmail,
          phone: contactPhone,
          link: contactForm
        },
        legalNoticeUrl: legalNotice === false ? "" : legalNotice.url,
        privacyPolicyUrl: privacyPolicy === false ? "" : privacyPolicy.url
      };
    }
    return {
      provider,
      contact: providerContact,
      privacyPolicyUrl: providerPrivacyPolicyUrl,
      legalNoticeUrl: providerLegalNoticeUrl
    };
  }, [isProviderCurrentWebsite, provider, providerContact, providerPrivacyPolicyUrl, providerLegalNoticeUrl, websiteOperator, legalNotice, privacyPolicy, contactForm]);
  const hasProviderContact = useMemo(() => Object.values(providerData.contact).filter(Boolean).length > 0, [providerData.contact]);
  return h(Fragment, null, !!purpose && h(CookieProperty, {
    label: i18n.purpose,
    value: reactNl2Br(purpose)
  }), h(CookieProperty, {
    label: i18n.legalBasis,
    value: legalBasis === "legal-requirement" ? i18n.legalRequirement : legalBasis === "legitimate-interest" || isEssentialGroup ? i18n.legitimateInterest : i18n.consent
  }), h(CookieProperty, {
    label: i18n.provider,
    value: providerData.provider
  }, hasProviderContact && h(Fragment, null, h(CookieProperty, {
    label: i18n.providerContactPhone,
    value: providerData.contact.phone,
    printValueAs: "phone"
  }), h(CookieProperty, {
    label: i18n.providerContactEmail,
    value: providerData.contact.email,
    printValueAs: "mail"
  }), h(CookieProperty, {
    label: i18n.providerContactLink,
    value: providerData.contact.link
  }))), h(CookieProperty, {
    label: i18n.providerPrivacyPolicyUrl,
    value: providerData.privacyPolicyUrl
  }), h(CookieProperty, {
    label: i18n.providerLegalNoticeUrl,
    value: providerData.legalNoticeUrl
  }), !!isEPrivacyUSA && h(CookieProperty, {
    label: i18n.ePrivacyUSA,
    value: ePrivacyUSA,
    printValueAs: "boolean"
  }), dataProcessingInUnsafeCountries.length > 0 && h(CookieProperty, {
    label: i18n.dataProcessingInUnsafeCountries,
    value: dataProcessingInUnsafeCountries.join(", ")
  }), designVersion > 4 && dataProcessingInCountriesSpecialTreatments.indexOf(EServiceTemplateDataProcessingInCountriesSpecialTreatment.StandardContractualClauses) > -1 && h(CookieProperty, {
    label: i18n.appropriateSafeguard,
    value: i18n.standardContractualClauses
  }), !isEmbeddingOnlyExternalResources && technicalDefinitions.map(_ref2 => {
    let {
      type,
      name,
      host,
      duration,
      durationUnit,
      isSessionDuration,
      purpose
    } = _ref2;
    return h(CookieProperty, {
      key: `${name}-${host}`,
      label: i18n.technicalCookieDefinition,
      monospace: true,
      value: applyDynamicsToHtml(name, codeDynamics)
    }, h(CookieProperty, {
      label: i18n.type,
      value: types[type].name
    }), h(CookieProperty, {
      label: i18n.purpose,
      value: purpose
    }), !!host && h(CookieProperty, {
      label: i18n.host,
      value: host,
      monospace: true
    }), h(CookieProperty, {
      label: i18n.duration,
      value: ["local", "indexedDb"].indexOf(type) > -1 ? i18n.noExpiration : isSessionDuration || type === "session" ? "Session" : generateDurationText(duration, durationUnit)
    }));
  }));
};
function calculateUnsafeCountries(_ref3) {
  let {
    dataProcessingInCountries,
    safeCountries,
    specialTreatments = [],
    designVersion = CONTEXT_LATEST_DESIGN_VERSION
  } = _ref3;
  const useSpecialTreatments = designVersion > 4 ? specialTreatments : [];
  return dataProcessingInCountries.filter(c => useSpecialTreatments.indexOf(EServiceTemplateDataProcessingInCountriesSpecialTreatment.StandardContractualClauses) > -1 ? false : safeCountries.indexOf(c) === -1 || c === "US" && useSpecialTreatments.indexOf(EServiceTemplateDataProcessingInCountriesSpecialTreatment.ProviderIsSelfCertifiedTransAtlanticDataPrivacyFramework) === -1);
}
function getCookieTypeLocalizationMap() {
  return {
    http: {
      name: "HTTP Cookie",
      abbr: "HTTP",
      backgroundColor: "black"
    },
    local: {
      name: "Local Storage",
      abbr: "Local",
      backgroundColor: "#b3983c"
    },
    session: {
      name: "Session Storage",
      abbr: "Session",
      backgroundColor: "#3c99b3"
    },
    indexedDb: {
      name: "IndexedDB",
      abbr: "I-DB",
      backgroundColor: "#4ab33c"
    }
  };
}
export { CookiePropertyList, calculateUnsafeCountries, getCookieTypeLocalizationMap, useDurationText };