import { useEffect, useState } from "react";
function useResettableAnimation(animation, duration) {
  const [i, setI] = useState(0);
  const [j, setJ] = useState(0);
  const [stateAnimation, setStateAnimation] = useState(animation);
  const [stateAnimationReset, setStateAnimationReset] = useState(undefined);
  const [stateDuration, setStateDuration] = useState(duration);
  const [stateDurationReset, setStateDurationReset] = useState(undefined);

  // Reset animation, save it temporary so when changing it, it needs to be to "none" and then the needed one
  useEffect(() => {
    // Avoid first rendering as the first is animated at mount-time
    if (i > 0) {
      if (animation === "none") {
        setStateAnimation(animation);
      } else {
        setStateAnimation("none");
        setStateAnimationReset(animation);
      }
    }
    setI(i + 1);
  }, [animation]);
  useEffect(() => {
    // Avoid first rendering as the first is animated at mount-time
    if (j > 0) {
      if (duration === 0) {
        setStateDuration(duration);
      } else {
        setStateDuration(0);
        setStateDurationReset(duration);
        setStateAnimation("none");
        setStateAnimationReset(animation);
      }
    }
    setJ(j + 1);
  }, [duration]);
  useEffect(() => {
    if (stateAnimationReset !== undefined) {
      setStateAnimation(stateAnimationReset);
      setStateAnimationReset(undefined);
    }
  }, [stateAnimationReset]);
  useEffect(() => {
    if (stateDurationReset !== undefined) {
      setStateDuration(stateDurationReset);
      setStateDurationReset(undefined);
    }
  }, [stateDurationReset]);
  return [stateAnimation, stateDuration];
}
export { useResettableAnimation };