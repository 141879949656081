import { mapValueSuffixPx } from "@devowl-wp/web-scoped-css";
/**
 * Common styles including selects, buttons, links, switch, ...
 */
const extendCommonContentStylesheet = [Symbol("extendCommonContentStylesheet"), (_ref, _ref2) => {
  let {
    className,
    rule,
    boolIf,
    control,
    jsx
  } = _ref;
  let {
    boolLargeOrMobile,
    design,
    headerDesign,
    footerDesign
  } = _ref2;
  const hasCloseIcon = className();

  /**
   * Why two containers for a simple close icon? I need to explain something technical here.
   * Our complete banner/dialog is tainted with `overflow:hidden` due to flickering effects
   * with animate.css etc. We will now create an invisible element with the placeholder,
   * we will then read its positioning (rect) and generate the visible close icon based on this position.
   */
  const [closeIcon, [closeIconFramed, closeIconPortalPlaceholder, closeIconPortal, closeNotPortalIcon]] = control({
    color: "#000000",
    width: 10,
    thickness: 1,
    rectX: 0,
    rectY: 0
  }, {
    width: mapValueSuffixPx,
    thickness: mapValueSuffixPx,
    // Only needed for portaled closed icon
    rectX: mapValueSuffixPx,
    rectY: mapValueSuffixPx
  }, _ref3 => {
    let {
      color,
      width,
      thickness,
      rectX,
      rectY
    } = _ref3;
    const framedClass = className();
    const [portalPlaceholderSelector, portalPlaceholder] = rule({
      display: "block"
    });
    const [portalIconSelector, portalIcon] = rule({
      position: "fixed !important",
      zIndex: 99,
      top: rectY(),
      left: rectX()
    });
    const [notPortalIconSelector, notPortalIcon] = rule({
      display: "block"
    });
    rule({
      forceSelector: `${portalPlaceholderSelector},${notPortalIconSelector}`,
      position: "absolute",
      top: "50%",
      right: "0px",
      display: "block",
      pointerEvents: "none",
      marginTop: `calc(${width()} / 2 * -1)`
    });
    rule({
      forceSelector: `${notPortalIconSelector},${portalIconSelector}`,
      cursor: "pointer",
      pointerEvents: "all",
      pseudos: {
        [`.${framedClass}`]: {
          outline: "rgb(255, 94, 94) solid 5px"
        },
        ":after": {
          width: "auto !important"
        },
        ">span>span": {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "rotate(45deg) translate(-50%, -50%)",
          transformOrigin: "top left",
          transition: "all 420ms",
          opacity: "0.5",
          pointerEvents: "none",
          backgroundColor: color(),
          width: thickness(),
          height: width()
        },
        ">span>span:nth-child(1)": {
          transform: "rotate(-45deg) translate(-50%, -50%)"
        },
        ":hover>span>span": {
          opacity: 1,
          width: `calc(${thickness()} + 1px)`
        }
      }
    });
    rule({
      forceSelector: `${notPortalIconSelector},${portalIconSelector},${portalPlaceholderSelector}`,
      width: width(),
      aspectRatio: "1/1"
    });
    return [framedClass, portalPlaceholder, portalIcon, notPortalIcon];
  });
  const [HeaderTitle] = jsx("div", {
    classNames: "headline",
    all: "unset",
    color: headerDesign.fontColor(),
    lineHeight: 1.8,
    fontSize: boolLargeOrMobile(headerDesign.fontSize, boolIf),
    fontFamily: boolIf(headerDesign.fontInheritFamily, "inherit", headerDesign.fontFamily()),
    fontWeight: headerDesign.fontWeight(),
    pseudos: {
      [`.${hasCloseIcon}`]: {
        paddingRight: "20px"
      }
    }
  }, {
    // [a11y]: Do not use `<h3>` as this could break ToC plugins
    role: "heading",
    "aria-level": "3"
  });
  const footerLinkTextDecoration = design.linkTextDecoration();
  const [FooterLink] = jsx("a", {
    classNames: "footer-link",
    textDecoration: footerLinkTextDecoration,
    fontSize: boolLargeOrMobile(footerDesign.fontSize, boolIf),
    color: footerDesign.fontColor(),
    fontFamily: boolIf(footerDesign.fontInheritFamily, "inherit", footerDesign.fontFamily()),
    padding: "0 5px",
    pseudos: {
      ":hover": {
        color: footerDesign.hoverFontColor(),
        // Can be overwritten by custom style due to specificity
        textDecoration: footerLinkTextDecoration
      }
    }
  });
  const footerFontSize = boolLargeOrMobile(footerDesign.fontSize, boolIf);
  const [FooterLanguageSwitcherSelect] = jsx("span", {
    padding: "0 5px",
    position: "relative",
    cursor: "pointer",
    pseudos: {
      ">select": {
        all: "unset",
        background: "transparent",
        border: 0,
        fontSize: footerFontSize,
        color: footerDesign.fontColor(),
        fontFamily: boolIf(footerDesign.fontInheritFamily, "inherit", footerDesign.fontFamily()),
        textDecoration: "underline",
        paddingRight: "15px"
      },
      '[data-flag="true"]>select': {
        paddingLeft: "23px"
      },
      " option": {
        background: design.bg()
      },
      "::after": {
        position: "absolute",
        content: "''",
        border: `solid ${footerDesign.fontColor()}`,
        borderWidth: "0 2px 2px 0",
        display: "inline-block",
        padding: "2px",
        transform: "rotate(45deg) translate(-50%, -50%)",
        right: "8px",
        top: "50%",
        pointerEvents: "none"
      },
      " span": {
        position: "absolute",
        left: "6px",
        top: "0px",
        bottom: "0px",
        width: "17px",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "contain",
        pointerEvents: "none"
      }
    }
  });
  return {
    hasCloseIcon,
    closeIcon: {
      framed: closeIconFramed,
      closeIcon,
      portalPlaceholder: closeIconPortalPlaceholder,
      portalIcon: closeIconPortal,
      notPortalIcon: closeNotPortalIcon
    },
    HeaderTitle,
    FooterLink,
    FooterLanguageSwitcherSelect
  };
}];
export { extendCommonContentStylesheet };