import _extends from "@babel/runtime/helpers/extends";
import { extendCommonGroupsStylesheet } from "@devowl-wp/web-cookie-banner";
import { useStylesheet } from "../../../contexts";
const Group = _ref => {
  let {
    headline,
    borderless,
    children,
    legend,
    ...rest
  } = _ref;
  const {
    Group,
    GroupInner,
    GroupDescription,
    screenReaderOnlyClass
  } = useStylesheet().extend(...extendCommonGroupsStylesheet);
  const useLegend = legend ? legend : typeof headline === "string" ? headline : undefined;
  const inner = h(GroupInner, null, useLegend && h("legend", {
    className: screenReaderOnlyClass
  }, useLegend), h("span", {
    "aria-hidden": !!useLegend
  }, headline), children && h(GroupDescription, null, children));
  return borderless ? h("div", _extends({
    className: "group"
  }, rest), inner) : h(Group, rest, inner);
};
export { Group };