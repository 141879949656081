import { putHtmlCodeToDom } from "@devowl-wp/headless-content-unblocker";
import { getServiceSelection } from "../decision";
import { OPT_IN_EVENT } from "../events";
/**
 * Opt-in to a set of services.
 */
async function applyOptIn(options) {
  const allOptInServices = [];
  const {
    isManagerActive,
    iterateServices
  } = getServiceSelection(options);
  const {
    skipOptIn
  } = options;

  // Handle managers like GTM and MTM
  let isManagerOptOut = false;
  let dataLayer = undefined;
  const waitPromises = [];

  // Handle opt-ins sequentially
  await iterateServices(async (group, service, isOptIn, _ref) => {
    let {
      getDataLayer,
      serviceIsManager
    } = _ref;
    const {
      codeDynamics,
      codeOptIn,
      executeCodeOptInWhenNoTagManagerConsentIsGiven
    } = service;
    const isManager = isManagerActive && serviceIsManager;
    if (isOptIn) {
      // Check if the script should explicitly only be loaded if no consent is given to the manager
      const isOnlyWhenNoConsentToManager = isManagerActive && executeCodeOptInWhenNoTagManagerConsentIsGiven;
      const isSkipOptIn = typeof skipOptIn === "function" ? skipOptIn(service) : false;

      // If this is a manager, calculate consent data for the datalayer and push it
      /* onlypro:start */
      if (isManager) {
        const realCookieBannerConsents = {};
        const realCookieBannerOptInEvents = {};
        const realCookieBannerOptOutEvents = {};
        await iterateServices((group, service, isOptIn, _ref2) => {
          let {
            serviceIsManager
          } = _ref2;
          if (!serviceIsManager) {
            const {
              tagManagerOptInEventName,
              tagManagerOptOutEventName,
              uniqueName
            } = service;
            if (tagManagerOptInEventName) {
              realCookieBannerOptInEvents[tagManagerOptInEventName] = isOptIn;
            }
            if (tagManagerOptOutEventName) {
              realCookieBannerOptOutEvents[tagManagerOptOutEventName] = !isOptIn;
            }
            if (uniqueName) {
              realCookieBannerConsents[uniqueName] = isOptIn;
            }
          }
        });
        Object.assign(realCookieBannerConsents, realCookieBannerOptInEvents);
        Object.assign(realCookieBannerConsents, realCookieBannerOptOutEvents);
        dataLayer = getDataLayer();
        dataLayer.push({
          realCookieBannerConsents,
          realCookieBannerOptInEvents,
          realCookieBannerOptOutEvents
        });
      }
      /* onlypro:end */

      if (!isOnlyWhenNoConsentToManager && !isSkipOptIn) {
        waitPromises.push(putHtmlCodeToDom(codeOptIn, codeDynamics));
      }
      const summary = {
        group,
        service
      };
      document.dispatchEvent(new CustomEvent(OPT_IN_EVENT, {
        detail: summary
      }));
      allOptInServices.push(summary);
    } else if (isManager) {
      /* onlypro:start */
      // It is a manager but opt-out
      isManagerOptOut = true;
      /* onlypro:end */
    }
  });

  return {
    isManagerOptOut,
    dataLayer,
    services: allOptInServices,
    ready: Promise.all(waitPromises)
  };
}
export { applyOptIn };