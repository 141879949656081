/**
 * Get the default decision and respect essential group and legitimate interests.
 */
function getDefaultDecision(_ref) {
  let {
    groups,
    essentialGroupSlug
  } = _ref;
  let respectLegitimateInterests = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  const [essentialGroupObj] = groups.filter(_ref2 => {
    let {
      slug
    } = _ref2;
    return slug === essentialGroupSlug;
  });

  // We need always use the current essential group
  const result = {
    [essentialGroupObj.id]: essentialGroupObj.items.map(_ref3 => {
      let {
        id
      } = _ref3;
      return id;
    })
  };
  if (respectLegitimateInterests) {
    for (const group of groups) {
      if (group === essentialGroupObj) {
        continue;
      }
      const ids = group.items.filter(_ref4 => {
        let {
          legalBasis
        } = _ref4;
        return legalBasis === "legitimate-interest";
      }).map(_ref5 => {
        let {
          id
        } = _ref5;
        return id;
      });
      if (ids.length) {
        result[group.id] = ids;
      }
    }
  }
  return result;
}
export { getDefaultDecision };