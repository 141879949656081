import { useMemo, useRef } from "react";
import { useLayoutEffect, useEffect } from "react";
import { useBanner, useBannerActionLinks, useBannerHistory, BannerAnimatedContent } from "../..";
import { useMainStylesheetForContext } from "../../hooks";
import { extendBannerStylesheet, fastdom } from "@devowl-wp/web-cookie-banner";
import { StyleSheetContextFactory } from "../../contexts";
const RECORD_ITEM_TYPE_CUSTOM_CONSENT_GROUPS = 0;
const Banner = () => {
  const banner = useBanner();
  const {
    recorder,
    visible,
    skipOverlay,
    pageRequestUuid4,
    individualPrivacyOpen,
    consent,
    layout: {
      overlay
    }
  } = banner;
  const dialogRef = useRef();
  const stylesheet = useMainStylesheetForContext();
  stylesheet.specify(pageRequestUuid4);
  const {
    a11yIds: {
      firstButton
    },
    Dialog,
    Overlay,
    individualPrivacyOpen: individualPrivacyOpenCssVariable,
    registerWindowResize
  } = stylesheet.extend(...extendBannerStylesheet);
  useMemo(() => individualPrivacyOpenCssVariable.update(individualPrivacyOpen), [individualPrivacyOpen]);

  // Listen to window resizes and resize the content automatically
  useLayoutEffect(registerWindowResize, []);
  useBannerHistory();
  useBannerActionLinks();

  // Automatically restart recorder when banner got visible again (e.g. cookie banner on first website
  // visit and then "Change privacy settings" link / dialog).
  useEffect(() => {
    if (visible && recorder) {
      fastdom.mutate(() => {
        recorder.restart();
      });
    }
  }, [visible, recorder]);
  useEffect(() => {
    if (recorder) {
      recorder.getRecords().addCustom(RECORD_ITEM_TYPE_CUSTOM_CONSENT_GROUPS, consent.groups);
    }
  }, [recorder, consent]);
  const StyleSheetContext = StyleSheetContextFactory.Context();

  // Show the banner with the dialog-API
  useEffect(() => {
    const dialogElement = dialogRef.current;

    // Listen to `Escape` and automatically focus the "Skip to consent choices" button
    const escapeListener = function (e) {
      this.querySelector(`a[href="#${firstButton}"]`).focus();
      e.preventDefault();
    };
    if (visible) {
      // Always show dialog in modal so screen reader users get the cookie banner as first element
      // when visiting the website when overlay is active. Also check for `isConnected` if the element is attached
      // to the current DOM. E.g. with `React.createPortal()` it is not attached at time of `useEffect`
      // to the DOM and we are getting an error "Uncaught DOMException: Failed to execute 'showModal'
      // on 'HTMLDialogElement': The element is not in a Document.". We do not need necesserily call the
      // `show` method on the dialog, as it is shown always with the `display: flex` property.
      //
      // Additionally, check if `.show()` method is available on the HTML dialog element as we do not have a
      // polyfill installed and the visibility is controlled through CSS. The fact, that in older browsers the
      // "modal" functionality is not given, is OK for us.
      if (dialogElement !== null && dialogElement !== void 0 && dialogElement.isConnected) {
        if (dialogElement.open) {
          dialogElement.close();
        }
        fastdom.mutate(() => {
          var _dialogElement;
          (_dialogElement = dialogElement[overlay ? "showModal" : "show"]) === null || _dialogElement === void 0 ? void 0 : _dialogElement.call(dialogElement);
        });
        dialogElement.addEventListener("cancel", escapeListener);
      }
    }

    // Not needed as we hardly remove the element from the DOM
    /*return () => {
        if (dialogElement) {
            dialogElement.removeEventListener("keyup", escapeListener);
            dialogElement.close();
        }
    };*/
  }, [visible, overlay]);

  // Refocus whne layer changes
  useEffect(() => {
    if (visible) {
      fastdom.mutate(() => dialogRef.current.focus({
        preventScroll: true
      }));
    }
  }, [visible, individualPrivacyOpen]);
  if (!visible) {
    return null;
  }
  const content = h(Dialog, {
    className: `${"wp-exclude-emoji"} ${individualPrivacyOpen ? "second-layer" : ""}`,
    ref: dialogRef
  }, h(BannerAnimatedContent, null));
  return h(StyleSheetContext.Provider, {
    value: stylesheet
  }, skipOverlay ? content : h(Overlay, {
    id: pageRequestUuid4,
    className: stylesheet.className
  }, content));
};
export { Banner, RECORD_ITEM_TYPE_CUSTOM_CONSENT_GROUPS };