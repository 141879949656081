const cache = {};
const CHARS_FROM = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
const CHARS_TO = "aaaaeeeeiiiioooouuuunc------";
const CHARS_FROM_ESCAPED = CHARS_FROM.replace(/\//g, "\\/");
const REGEXP = new RegExp(`[${CHARS_FROM_ESCAPED}]`, "g");

/**
 * Slugify a given text.
 *
 * @see https://gist.github.com/codeguy/6684588
 */
function slugify(str) {
  if (cache[str]) {
    return cache[str];
  }
  const newStr = str.trim().toLowerCase().replace(REGEXP, m => CHARS_TO.charAt(CHARS_FROM.indexOf(m))).replace(/[^a-z0-9 -]/g, "") // remove invalid chars
  .replace(/\s+/g, "-") // collapse whitespace and replace by -
  .replace(/-+/g, "-"); // collapse dashes

  cache[str] = newStr;
  return newStr;
}
export { slugify };