import { Fragment, useMemo } from "react";
import { CONTEXT_LATEST_DESIGN_VERSION } from "../../../types";
import { useBanner, useBannerButtonCallbacks, useBannerStylesheet } from "../../..";
import { Button } from "../../common";
const BannerButtonList = () => {
  const banner = useBanner();
  const {
    isConsentRecord,
    activeAction,
    bodyDesign: {
      acceptEssentialsUseAcceptAll,
      acceptAllOneRowLayout
    },
    decision: {
      showGroups,
      groupsFirstView,
      saveButton,
      acceptAll,
      acceptEssentials,
      buttonOrder: buttonOrderString
    },
    texts: {
      acceptAll: acceptAllText,
      acceptEssentials: acceptEssentialsText,
      acceptIndividual: acceptIndividualText
    },
    saveButton: {
      type: saveButtonType,
      useAcceptAll: saveButtonUseAcceptAll
    },
    individualTexts: {
      save
    },
    individualPrivacyOpen,
    didGroupFirstChange,
    productionNotice,
    buttonClicked = "",
    designVersion = CONTEXT_LATEST_DESIGN_VERSION
  } = banner;
  const {
    a11yIds: {
      firstButton
    }
  } = useBannerStylesheet();
  const useAcceptEssentialsUseAcceptAll = acceptEssentialsUseAcceptAll && acceptAll === acceptEssentials;
  const useSaveButtonUseAcceptAll = saveButtonUseAcceptAll && acceptAll === saveButtonType;

  // Calculate order of the buttons depending on a string like `all,essential,save,individual`
  const {
    all: buttonOrderAll,
    essential: buttonOrderEssential,
    individual: buttonOrderIndividual,
    save: buttonOrderSave
  } = useMemo(() => {
    const buttonOrder = buttonOrderString.split(",");
    const buttonToOrder = buttonOrder.reduce((previous, curr) => {
      previous[curr] = buttonOrder.indexOf(curr);
      return previous;
    }, {});
    const orderToButton = buttonOrder.reduce((previous, curr) => {
      previous[buttonOrder.indexOf(curr)] = curr;
      return previous;
    }, {});

    // If one-row layout is enabled, reverse the first two orders so the first button gets moved to the right
    if (acceptAllOneRowLayout) {
      buttonToOrder[orderToButton[0]] = 1;
      buttonToOrder[orderToButton[1]] = 0;
    }
    return buttonToOrder;
  }, [buttonOrderString, acceptAllOneRowLayout]);
  const isPro = process.env.PLUGIN_CTX === "pro";
  const {
    buttonClickedAll,
    buttonClickedEssentials,
    buttonClickedCustom,
    acceptAll: handleAll,
    acceptEssentials: handleEssentials,
    acceptIndividual: handleIndividual,
    openIndividualPrivacy
  } = useBannerButtonCallbacks();

  // We expect a reload of the page for the "change" action (keeps also dialog open)
  const busyOnClick = activeAction === "change" && !isConsentRecord;
  const groupsFirstViewEnabled = showGroups && groupsFirstView;

  // Calculate button visibility
  const showSaveButtonInsteadOfAcceptAll = !individualPrivacyOpen && isPro && groupsFirstViewEnabled && saveButton === "afterChangeAll" && didGroupFirstChange;
  const showEssentials = activeAction !== "change";
  const showStandaloneSaveButton = individualPrivacyOpen || isPro && groupsFirstViewEnabled && (saveButton === "always" || saveButton === "afterChange" && didGroupFirstChange);
  const showIndividual = !individualPrivacyOpen;
  return h(Fragment, null, showSaveButtonInsteadOfAcceptAll ? h(Button, {
    onClick: handleIndividual,
    busyOnClick: busyOnClick,
    order: buttonOrderAll,
    type: "acceptAll",
    framed: buttonClicked === buttonClickedCustom,
    id: firstButton
  }, save) : h(Button, {
    onClick: handleAll,
    busyOnClick: busyOnClick,
    order: buttonOrderAll,
    type: "acceptAll",
    framed: buttonClicked === buttonClickedAll,
    id: firstButton
  }, acceptAllText), (showEssentials || designVersion > 2) && h(Button, {
    onClick: handleEssentials,
    busyOnClick: busyOnClick,
    order: buttonOrderEssential,
    type: useAcceptEssentialsUseAcceptAll ? "acceptAll" : "acceptEssentials",
    framed: buttonClicked === buttonClickedEssentials
  }, acceptEssentialsText), showStandaloneSaveButton && h(Button, {
    onClick: handleIndividual,
    busyOnClick: busyOnClick,
    order: buttonOrderSave,
    type: useSaveButtonUseAcceptAll ? "acceptAll" : "save",
    framed: buttonClicked === buttonClickedCustom
  }, save), showIndividual && h(Button, {
    onClick: openIndividualPrivacy,
    busyOnClick: busyOnClick,
    order: buttonOrderIndividual,
    type: "acceptIndividual",
    framed: buttonClicked.startsWith("ind_")
  }, acceptIndividualText), productionNotice);
};
export { BannerButtonList };