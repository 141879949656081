import { extendCommonContentStylesheet } from "@devowl-wp/web-cookie-banner";
import { useStylesheet } from "../../../contexts";
const FooterLink = _ref => {
  let {
    children,
    ...anchorRest
  } = _ref;
  const {
    FooterLink
  } = useStylesheet().extend(...extendCommonContentStylesheet);
  return h(FooterLink, anchorRest, children);
};
export { FooterLink };