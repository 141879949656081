import { CookieConsentManager } from "../apply";
import { apply } from "../apply";
import { BANNER_PRE_DECISION_SHOW_EVENT, BANNER_PRE_DECISION_SHOW_INTERACTIVE_EVENT } from "../events";
import { ready } from "../utils";
async function preDecisionGateway(options, handler) {
  let show = true;
  const useOptions = options instanceof CookieConsentManager ? options.getOptions() : options;
  const {
    gateways,
    args,
    onIsDoNotTrack,
    onShowCookieBanner
  } = handler;
  for (const gateway of gateways) {
    const result = await gateway(useOptions, ...args);
    if (result !== false) {
      show = false;
      const applyWithOptions = type => apply({
        type,
        ...useOptions
      });
      if (result === "all") {
        applyWithOptions("all");
      } else if (result === "essentials") {
        applyWithOptions("essentials");
      } else if (result === "dnt") {
        onIsDoNotTrack(() => applyWithOptions("essentials"));
      } else if (result === "consent") {
        // Apply services for the current user decision
        applyWithOptions("consent");
      }
      break;
    }
  }
  if (show) {
    onShowCookieBanner();
    document.dispatchEvent(new CustomEvent(BANNER_PRE_DECISION_SHOW_INTERACTIVE_EVENT));

    // Wait all events are initialized
    await ready();
    document.dispatchEvent(new CustomEvent(BANNER_PRE_DECISION_SHOW_EVENT, {
      detail: {}
    }));
  }
}
export { preDecisionGateway };