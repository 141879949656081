import { createStylesheet } from "@devowl-wp/web-scoped-css";
import hintCss from "hint.css/hint.min.css?inline-raw";
const createHintCss = _ref => {
  let {
    title,
    position,
    color,
    size,
    always,
    rounded,
    noAnimate,
    bounce,
    className
  } = _ref;
  createStylesheet(hintCss, {
    id: "hint-css",
    overwrite: false
  });
  return {
    "aria-label": title,
    className: `hint--${position} hint--${size} ${color ? `hint--${color}` : ""} ${always ? `hint--always` : ""} ${rounded ? `hint--rounded` : ""} ${noAnimate ? `hint--no-animate` : ""} ${bounce ? `hint--bounce` : ""} ${className || ""}`
  };
};
export { createHintCss as hintCss };