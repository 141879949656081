import { useCallback } from "react";
import { Checkbox, LinkToggle, useBanner, BannerCookie } from "../../..";
import { Group } from "../../common";
const BannerGroup = _ref => {
  var _consent$groups$id;
  let {
    group
  } = _ref;
  const banner = useBanner();
  const {
    id,
    slug,
    name,
    description,
    items
  } = group;
  const {
    previewCheckboxActiveState,
    consent,
    activeAction,
    essentialGroup,
    group: {
      headlineFontSize
    },
    individualTexts: {
      headline,
      showMore,
      hideMore
    }
  } = banner;
  const isEssentialGroup = essentialGroup === slug;
  const isDisabled = isEssentialGroup || activeAction === "history";
  const isChecked = previewCheckboxActiveState || isEssentialGroup || !!consent.groups[id];
  const isPartial = !isEssentialGroup && ((_consent$groups$id = consent.groups[id]) === null || _consent$groups$id === void 0 ? void 0 : _consent$groups$id.length) && JSON.stringify(items.map(_ref2 => {
    let {
      id
    } = _ref2;
    return id;
  }).sort()) !== JSON.stringify(consent.groups[id].sort() || []);
  const handleCheckbox = useCallback(checked => banner.updateGroupChecked(id, checked), [banner, id, isChecked]);
  return h(Group, {
    legend: `${headline}: ${name}`,
    headline: h(Checkbox, {
      onToggle: handleCheckbox,
      isPartial: isPartial,
      isChecked: isChecked,
      isDisabled: isDisabled,
      fontSize: headlineFontSize
    }, name, " (", items.length, ")")
  }, h("span", null, description), !!items && h(LinkToggle, {
    showMore: showMore,
    hideMore: hideMore
  }, items.map(cookie => h(BannerCookie, {
    key: cookie.id,
    group: group,
    cookie: cookie
  }))));
};
export { BannerGroup };