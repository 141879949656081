import { extendCommonGroupsStylesheet } from "@devowl-wp/web-cookie-banner";
import { BannerDottedGroup } from ".";
import { useBanner, useBannerStylesheet } from "../../..";
const BannerDottedGroupList = () => {
  const {
    texts: {
      headline
    }
  } = useBanner();
  const {
    DottedGroupList,
    screenReaderOnlyClass
  } = useBannerStylesheet().extend(...extendCommonGroupsStylesheet);
  const {
    groups,
    decision: {
      showGroups
    }
  } = useBanner();
  return showGroups ? h(DottedGroupList, null, h("legend", {
    className: screenReaderOnlyClass
  }, headline), groups.filter(_ref => {
    let {
      items
    } = _ref;
    return !!items.length;
  }).map(group => h(BannerDottedGroup, {
    key: group.id,
    group: group
  }))) : null;
};
export { BannerDottedGroupList };