import { useState, useEffect } from "react";
function getAnimationRelatedState(_ref) {
  let {
    isVisible,
    animationIn,
    animationOut,
    animationInDuration,
    animationOutDuration,
    animationInDelay,
    animationOutDelay
  } = _ref;
  return isVisible ? {
    animation: animationIn,
    duration: animationInDuration,
    delay: animationInDelay
  } : {
    animation: animationOut,
    duration: animationOutDuration,
    delay: animationOutDelay
  };
}

/**
 * Ported from https://www.npmjs.com/package/react-animated-css due to high bundle size.
 */
const AnimatedCss = _ref2 => {
  let {
    animateOnMount = true,
    isVisible = true,
    animationIn = "fadeIn",
    animationOut = "fadeOut",
    animationInDelay = 0,
    animationOutDelay = 0,
    animationInDuration = 1000,
    animationOutDuration = 1000,
    className = "",
    style = {},
    children
  } = _ref2;
  const [{
    animation,
    duration,
    delay
  }, setAnimationRelatedState] = useState(animateOnMount ? getAnimationRelatedState({
    isVisible,
    animationIn,
    animationOut,
    animationInDelay,
    animationOutDelay,
    animationInDuration,
    animationOutDuration
  }) : {
    animation: "",
    delay: undefined,
    duration: 0
  });
  useEffect(() => {
    setAnimationRelatedState(getAnimationRelatedState({
      isVisible,
      animationIn,
      animationOut,
      animationInDelay,
      animationOutDelay,
      animationInDuration,
      animationOutDuration
    }));
  }, [isVisible, animationIn, animationOut, animationInDelay, animationOutDelay, animationInDuration, animationOutDuration]);
  const classes = `animate__animated animate__${animation} ${className}`;
  const backwardStyle = !animation ? {
    opacity: isVisible ? 1 : 0,
    transition: `opacity ${delay}ms`
  } : {};
  return h("div", {
    className: classes,
    style: {
      animationDelay: `${delay}ms`,
      animationDuration: `${duration}ms`,
      pointerEvents: isVisible ? "all" : "none",
      ...style,
      ...backwardStyle
    }
  }, children);
};
export { AnimatedCss };