// Also ported to `view/Blocker.php`
export const HTML_ATTRIBUTE_CAPTURE_PREFIX = "consent-original";
export const HTML_ATTRIBUTE_CAPTURE_CLICK_PREFIX = "consent-click-original";
export const HTML_ATTRIBUTE_CAPTURE_SUFFIX = "_";
export const HTML_ATTRIBUTE_BY = "consent-by";

// TODO: cookie -> required?
export const HTML_ATTRIBUTE_COOKIE_IDS = "consent-required";
export const HTML_ATTRIBUTE_VISUAL_PARENT = "consent-visual-use-parent";
export const HTML_ATTRIBUTE_VISUAL_FORCE = "consent-visual-force";
export const HTML_ATTRIBUTE_VISUAL_PAINT_MODE = "consent-visual-paint-mode";
export const HTML_ATTRIBUTE_VISUAL_PARENT_HIDE = "consent-visual-use-parent-hide";
export const HTML_ATTRIBUTE_INLINE = "consent-inline";
export const HTML_ATTRIBUTE_INLINE_STYLE = "consent-inline-style";
export const HTML_ATTRIBUTE_BLOCKER_ID = "consent-id";
export const HTML_TAG_CONSENT_SCRIPT = "script";
export const HTML_ATTRIBUTE_BLOCKER_CONNECTED = "consent-blocker-connected";
export const HTML_ATTRIBUTE_BLOCKER_CONNECTED_PRESERVED = "consent-blocker-connected-pres";
export const HTML_ATTRIBUTE_UNBLOCKED_TRANSACTION_COMPLETE = "consent-transaction-complete";
export const HTML_ATTRIBUTE_TRANSFORM_WRAPPER = "consent-transform-wrapper";
export const HTML_ATTRIBUTE_TRANSFORM_WRAPPER_VALUE = "1";
export const HTML_ATTRIBUTE_WAS_PREVIOUSLY_STRICT_HIDDEN = "consent-strict-hidden";
export const HTML_ATTRIBUTE_RESET_PARENT = "consent-cb-reset-parent";
export const HTML_ATTRIBUTE_RESET_PARENT_VALUE = "1";
export const HTML_ATTRIBUTE_RESET_PARENT_IS_RATIO_CONTAINER = "consent-cb-reset-parent-is-ratio";
export const HTML_ATTRIBUTE_GOT_CLICKED = "consent-got-clicked";
export const HTML_ATTRIBUTE_GOT_CLICKED_VALUE = "1";
export const HTML_ATTRIBUTE_GOT_CLICKED_VALUE_DO_NOT_USE_IN_NESTED = "2";
export const HTML_ATTRIBUTE_THUMBNAIL = "consent-thumbnail";
export const HTML_ATTRIBUTE_THUMBNAIL_SUGGESTION = "consent-thumbnail-suggestion";
export const HTML_ATTRIBUTE_DELEGATE_CLICK = "consent-delegate-click";
export const HTML_ATTRIBUTE_JQUERY_HIJACK_EACH = "consent-jquery-hijack-each";
export const HTML_ATTRIBUTE_CLICK_DISPATCH_RESIZE = "consent-click-dispatch-resize";
export const HTML_ATTRIBUTE_CONFIRM = "consent-confirm";
export const HTML_ATTRIBUTE_HERO_DIALOG_DEFAULT_OPEN = "consent-hero-dialog-default-open";