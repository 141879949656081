const BASE32_TABLE = "ABCDEFGHIJKLMNOPQRSTUVWXYZ234567";
const OFFSET_EVERY_X_CHARACTER = 4;
function obfuscatePath(offset, str) {
  let mode = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "keep-last-part";
  let j = 0;
  const offsetBase64 = window.btoa(offset).toLowerCase();
  const result = str.split("/").map((part, i, arr) => {
    // Keep the last part of the full path to make it more "debuggable" in network tab of DevTools
    if (mode === "keep-last-part" && i === arr.length - 1) {
      return part;
    }

    // Use base32-encoded string as this allows us to send the path as all-lowercase
    const newPart = base32Encode(part
    // Chunk part into x letters, so the random offset gets appended after each x character
    .match(new RegExp(`.{1,${OFFSET_EVERY_X_CHARACTER}}`, "g")).map(letters => {
      if (letters.length < OFFSET_EVERY_X_CHARACTER) {
        return letters;
      }
      j = j >= offset.length ? 0 : j;
      const newLetters = [letters.split("").map(letter => {
        const letterInOffsetBase64 = offsetBase64.indexOf(letter);
        return letterInOffsetBase64 > -1 ? `/${letterInOffsetBase64}/` : letter;
      }).join(""), offset[j]];
      j++;
      return newLetters;
    }).flat().join(""))
    // Remove all `=` characters by masking the last character with the count of such characters
    .replace(/([=]+)?$/, m => {
      return `${m.length}`;
    }).toLowerCase();
    return newPart;
  });

  // The first integer in the path-part determines the mode
  result.splice(result.length - 1, 0, `${mode === "full" ? 1 : 0}${offset.toString()}`);
  return result.join("/");
}
function base32Encode(str) {
  const base64 = window.btoa(str);
  let base32 = "";
  let bits = 0;
  let value = 0;
  for (let i = 0; i < base64.length; i++) {
    value = value << 8 | base64.charCodeAt(i);
    bits += 8;
    while (bits >= 5) {
      base32 += BASE32_TABLE[value >>> bits - 5 & 0x1f];
      bits -= 5;
    }
  }
  if (bits > 0) {
    base32 += BASE32_TABLE[value << 5 - bits & 0x1f];
  }
  return base32;
}
export { obfuscatePath };