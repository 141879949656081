import { Fragment } from "react";
import { isUrl } from "@devowl-wp/headless-content-unblocker";
import { useBannerOrBlocker } from "../../../hooks";
import { useStylesheet } from "../../../contexts";
import { extendCommonGroupsStylesheet, extendCommonStylesheet } from "@devowl-wp/web-cookie-banner";
const CookieProperty = _ref => {
  let {
    label,
    value,
    children,
    printValueAs,
    monospace
  } = _ref;
  const stylesheet = useStylesheet();
  const {
    Link,
    CookieProperty
  } = stylesheet.extend(...extendCommonStylesheet).extend(...extendCommonGroupsStylesheet);
  const banner = useBannerOrBlocker();
  const {
    i18n: {
      yes,
      no
    }
  } = banner;
  const isValueUrl = typeof value === "string" && value.startsWith("http") && isUrl(value);
  let useValue = isValueUrl ? h(Link, {
    href: value,
    target: "_blank",
    rel: "noopener noreferrer"
  }, value) : typeof value === "string" ? printValueAs === "phone" ? h(Link, {
    target: "_blank",
    href: `tel:${value.replace(/\s+/g, "")}`
  }, value) : printValueAs === "mail" ? h(Link, {
    target: "_blank",
    href: `mailto:${value}`
  }, value) : h("span", {
    dangerouslySetInnerHTML: {
      __html: value
    }
  }) : value;
  if (printValueAs === "boolean") {
    useValue = useValue ? yes : no;
  }
  return value || value === false ? h(Fragment, null, h(CookieProperty, null, label && h("strong", null, label, ":\xA0"), h("span", {
    role: "presentation",
    style: {
      fontFamily: monospace ? "monospace" : undefined
    }
  }, useValue)), h(CookieProperty, null, !!children && h("div", null, children))) : null;
};
export { CookieProperty };