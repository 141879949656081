import { createRuleFactory } from ".";
const createNestedQueryFactory = (meta, varFactory) => (query, rules) => {
  const nestedRules = new Map();
  const {
    rule: createRule
  } = createRuleFactory({
    ...meta,
    rules: nestedRules
  }, varFactory);
  for (const selector in rules) {
    const properties = rules[selector];
    createRule({
      forceSelector: selector,
      ...properties
    });
  }
  meta.rules.set(query, Object.fromEntries(nestedRules));
};
export { createNestedQueryFactory };