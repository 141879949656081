const throttling = (timeout, callback) => {
  if (timeout === "raf") {
    let ticking = false;
    return () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          callback();
          ticking = false;
        });
        ticking = true;
      }
    };
  } else {
    let t;
    return () => {
      clearTimeout(t);
      t = setTimeout(callback, timeout);
    };
  }
};
export { throttling };