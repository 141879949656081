import { specifySelector } from ".";
const hyphenProperty = property => {
  const [firstChar] = property;
  if (firstChar.toUpperCase() === firstChar.toLowerCase() || property.indexOf("-") > -1) {
    return property;
  }
  return property.replace(/[A-Z]/g, m => `-${m.toLowerCase()}`);
};
const hyphenObject = object => {
  return Object.keys(object).reduce((previous, currentValue) => {
    let val = object[currentValue];
    val = typeof val === "function" ? val() : val;

    // Avoid string literals exposing functions to the resulting property value.
    // Example: width: `calc(${thickness} + 1px)` -> width: calc(function () {
    if (typeof val === "string" && val.indexOf("function () {") > -1) {
      throw new Error(`${currentValue} contains a serialized function ("${val}").`);
    }
    previous[hyphenProperty(currentValue)] = val;
    return previous;
  }, {});
};
const createClassNameFactory = function (meta) {
  let withDot = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return `${meta.className.substr(withDot ? 0 : 1)}-${meta.inc++}`;
};
const createRuleFactory = (meta, _ref) => {
  let {
    vars: varsFn
  } = _ref;
  const {
    id,
    specifiedIds
  } = meta;
  const {
    runPlugin
  } = meta;
  const createClassName = withDot => createClassNameFactory(meta, withDot);
  const createRule = properties => {
    runPlugin("modifyRule", properties);
    const {
      classNames,
      pseudos,
      forceSelector,
      ...rest
    } = properties;
    const useForceSelector = Array.isArray(forceSelector) ? forceSelector.join(" ") : forceSelector;
    const useClassNames = Array.isArray(classNames) ? classNames : classNames ? classNames.split(" ") : [];
    const selector = useForceSelector || createClassName(true);
    meta.rules.set(specifySelector(id, specifiedIds, selector), hyphenObject(rest));
    if (pseudos) {
      // Allow comma separated selectors to apply the pseudos too
      const selectors = selector.split(",");
      for (const pseudo in pseudos) {
        // Allow comma separated pseudos like `::after,::before`
        const pseudoSplit = pseudo.split(",");
        const ruleSelector = selectors.map(s => pseudoSplit.map(p => s === p ? undefined : p.startsWith("<") ? `${p.substr(1)}${s}` : `${s}${p}`)).flat().filter(Boolean).join(",");
        meta.rules.set(specifySelector(id, specifiedIds, ruleSelector), hyphenObject(pseudos[pseudo]));
      }
    }

    // Build class name
    const className = [selector.substr(1)];
    if (!forceSelector) {
      runPlugin("filterClassName", useClassNames, className[0], meta);
      className.push(...useClassNames);
    }
    return [selector, forceSelector ? undefined : className.join(" ")];
  };
  const createControl = (object, mapValue, create) => {
    const [consumer,, styleObject] = varsFn(object, mapValue, false);
    const result = create(consumer);
    return [styleObject, result, consumer, Object.keys(object)];
  };
  const createVariant = variables => {
    const className = createClassName(true);
    return [createRule(variables.reduce((a, _ref2) => {
      let [variableConsumer, expression] = _ref2;
      // Add extra whitespace so `VarConsumer#update` does not update this expression
      a[` ${variableConsumer(false)}`] = expression;
      return a;
    }, {
      forceSelector: `${meta.className}${className}`
    }))[0], className.substr(1)];
  };
  return {
    className: createClassName,
    rule: createRule,
    control: createControl,
    variant: createVariant
  };
};
export { createRuleFactory };