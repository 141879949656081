import { useBanner, BannerGroup } from "../../..";
import { GroupList } from "../../common";
const BannerGroupList = () => {
  const {
    groups
  } = useBanner();
  const useGroups = groups.filter(_ref => {
    let {
      items
    } = _ref;
    return items.length;
  });
  return h(GroupList, null, useGroups.map(group => h(BannerGroup, {
    key: group.id,
    group: group
  })));
};
export { BannerGroupList };