import { FooterLink } from ".";
import { Tooltip } from "../..";
const FooterPoweredByLink = _ref => {
  let {
    poweredLink,
    affiliate
  } = _ref;
  return h(FooterLink, {
    href: affiliate ? affiliate.link : poweredLink.href,
    target: poweredLink.target,
    key: "powered-by"
  }, h("span", {
    dangerouslySetInnerHTML: {
      __html: poweredLink.innerHTML
    }
  }), affiliate && h(Tooltip, {
    title: affiliate.description,
    position: "top-left"
  }, " ", affiliate.labelBehind));
};
export { FooterPoweredByLink };