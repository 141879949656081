import { SCALING_WHEN_WINDOW_WIDTH_LOWER } from "@devowl-wp/web-cookie-banner";
import { useBanner, useBannerButtonCallbacks, CloseIcon } from "../../..";
const BannerCloseIcon = () => {
  var _document$getElementB;
  const {
    headerDesign: {
      fontColor,
      fontSize
    },
    texts: {
      acceptEssentials
    },
    activeAction,
    pageRequestUuid4,
    i18n: {
      close,
      closeWithoutSaving
    },
    buttonClicked = ""
  } = useBanner();
  const {
    buttonClickedCloseIcon,
    closeIcon
  } = useBannerButtonCallbacks();
  const container = (_document$getElementB = document.getElementById(pageRequestUuid4)) === null || _document$getElementB === void 0 ? void 0 : _document$getElementB.querySelector("dialog");
  return h(CloseIcon, {
    width: fontSize,
    color: fontColor,
    tooltipText: activeAction ? activeAction === "change" ? closeWithoutSaving : close : acceptEssentials,
    tooltipAlways: (container === null || container === void 0 ? void 0 : container.clientWidth) < SCALING_WHEN_WINDOW_WIDTH_LOWER,
    framed: buttonClicked === buttonClickedCloseIcon,
    renderInContainer: container,
    onClick: closeIcon
  });
};
export { BannerCloseIcon };