/**
 * Checks if the given browser is requesting "Do Not Track" header.
 *
 * @see https://dev.to/corbindavenport/how-to-correctly-check-for-do-not-track-with-javascript-135d
 */
function isDoNotTrack() {
  try {
    const w = window;
    if (w.doNotTrack || w.navigator.doNotTrack || w.navigator.msDoNotTrack || "msTrackingProtectionEnabled" in w.external) {
      // The browser supports Do Not Track!
      if (w.doNotTrack == "1" || w.navigator.doNotTrack == "yes" || w.navigator.doNotTrack == "1" || w.navigator.msDoNotTrack == "1" || w.external.msTrackingProtectionEnabled()) {
        return true;
      }
    }
  } catch (e) {
    // Silence is golden.
  }
  return false;
}
export { isDoNotTrack };