const untrailingslashit = str => str.endsWith("/") || str.endsWith("\\") ? untrailingslashit(str.slice(0, -1)) : str;
const trailingslashit = str => `${untrailingslashit(str)}/`;

// Allows to make an interface extension and make some properties optional (https://git.io/JeK6J)

/**
 * You need to manually put the following code snippet in each of your entry points to enable
 * "on the fly" public path for chunks:
 *
 * ```
 * __webpack_public_path__ = getWebpackPublicPath(process.env.slug);
 * ```
 *
 * @see https://webpack.js.org/guides/public-path/#on-the-fly
 */
const getWebpackPublicPath = slug => {
  const options = window[slug.replace(/-([a-z])/g, g => g[1].toUpperCase())];
  return `${options.publicUrl}${options.chunkFolder}/`;
};
export { untrailingslashit, trailingslashit, getWebpackPublicPath };