import { supportsCookies } from "../utils";

/**
 * Does the current browser support cookies?
 */
const preDecisionGatewaySupportsCookies = async _ref => {
  let {
    supportsCookiesName
  } = _ref;
  return supportsCookies(supportsCookiesName) ? false : "essentials";
};
export { preDecisionGatewaySupportsCookies };