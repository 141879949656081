import { getUserDecision } from "../decision";
import { isCrawler } from "../utils";

/**
 * Prevent any predecision and user decision and force to reuse existing consent or all for crawlers.
 * This could be e.g. be configured by a dynamic hook or on server-side via a localized variable.
 */
const preDecisionGatewayIsPreventPreDecision = preventPreDecision => async _ref => {
  let {
    decisionCookieName
  } = _ref;
  const userDecision = getUserDecision(decisionCookieName);
  return preventPreDecision && userDecision === false ? isCrawler() ?
  // For crawlers we need to explicitly allow services on all pages
  "all" : "consent" : false;
};
export { preDecisionGatewayIsPreventPreDecision };