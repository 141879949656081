import { putHtmlCodeToDom } from "@devowl-wp/headless-content-unblocker";
import { getServiceSelection } from "../decision";
import { OPT_OUT_EVENT } from "../events";
import { deleteCookiesLocally } from ".";

/**
 * Opt-in to a set of services for a given data manager like GTM / MTM.
 */
async function applyOptOut(options, isManagerOptOut) {
  const allOptOutServices = [];
  const {
    isManagerActive,
    iterateServices
  } = getServiceSelection(options);
  const waitPromises = [];

  // Handle opt-outs sequentially
  const deleteHttpCookies = [];
  await iterateServices(async (group, service, isOptIn) => {
    const {
      id,
      codeDynamics,
      codeOptOut,
      deleteTechnicalDefinitionsAfterOptOut,
      isEmbeddingOnlyExternalResources,
      technicalDefinitions,
      executeCodeOptOutWhenNoTagManagerConsentIsGiven
    } = service;
    if (!isOptIn) {
      // Check if the script should explicitly only be loaded if no consent is given to the manager
      const isOnlyWhenNoConsentToManager = isManagerActive && executeCodeOptOutWhenNoTagManagerConsentIsGiven;
      if (isOnlyWhenNoConsentToManager && isManagerOptOut || !isOnlyWhenNoConsentToManager) {
        waitPromises.push(putHtmlCodeToDom(codeOptOut, codeDynamics));
      }
      if (deleteTechnicalDefinitionsAfterOptOut && !isEmbeddingOnlyExternalResources) {
        deleteCookiesLocally(technicalDefinitions, codeDynamics);

        // Mark deletable server-side services
        if (technicalDefinitions.some(_ref => {
          let {
            type
          } = _ref;
          return type === "http";
        })) {
          deleteHttpCookies.push(id);
        }
      }
      const summary = {
        group,
        service
      };
      document.dispatchEvent(new CustomEvent(OPT_OUT_EVENT, {
        detail: summary
      }));
      allOptOutServices.push(summary);
    }
  });
  return {
    services: allOptOutServices,
    ready: Promise.all(waitPromises),
    deleteHttpCookies
  };
}
export { applyOptOut };