import _extends from "@babel/runtime/helpers/extends";
import { Fragment, useState } from "react";
import { useStylesheet } from "../../contexts";
import { extendCommonStylesheet } from "@devowl-wp/web-cookie-banner";
import { useA11yId } from "../../hooks";
const LinkToggle = _ref => {
  let {
    onToggle,
    children,
    showMore,
    hideMore,
    ...rest
  } = _ref;
  const {
    Link
  } = useStylesheet().extend(...extendCommonStylesheet);
  const [isOpen, setIsOpen] = useState(false);
  const a11yExpanded = useA11yId();
  return h(Fragment, null, "\xA0\xA0\u2022\xA0\xA0", h(Link, _extends({
    href: "#",
    onClick: e => {
      const newOpen = !isOpen;
      setIsOpen(newOpen);
      onToggle === null || onToggle === void 0 ? void 0 : onToggle(newOpen);
      e.preventDefault();
    }
  }, children ? {
    "aria-expanded": isOpen,
    "aria-controls": a11yExpanded
  } : {}, rest), isOpen ? hideMore : showMore), children && h("div", {
    id: a11yExpanded
  }, isOpen && children));
};
export { LinkToggle };